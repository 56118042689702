<template>
    <div>
        <MainDashboard />
        <div v-for="(item, index) in noticeList" :key="item.noticeNo">
            <NoticeModal
                :notice-no="item.noticeNo"
                :title="item.noticeTitle"
                :content="item.noticeContext"
                :position-x="(index % modalsPerRow) * (modalXSize + modalGap) + startX"
                :position-y="Math.floor(index / modalsPerRow) * (modalYSize + modalGap) + startY"
                @closeModal="closeModal"
            />
        </div>
        <div v-if="isOnBackground" class="modal-background"></div>
    </div>
</template>

<script>
import api from '@/api/api-interceptors';
import TokenService from '@/api/tokenService';
import MainDashboard from '@/components/dashboard/MainDashboard.vue';
import NoticeModal from '@/components/dashboard/NoticeModal.vue';

export default {
    components: {
        MainDashboard,
        NoticeModal,
    },
    data: function () {
        return {
            user: TokenService.getUser(),
            noticeList: [],
            modalGap: 15,
            modalXSize: 500,
            modalYSize: 200,
            startX: 250,
            startY: 100,
            modalsPerRow: 2,
            isOnBackground: false,
            cntCloseModal: 0,
        };
    },
    created: function () {
        if (!this.user) {
            console.log('you have to login');
            this.$router.push('/login');
        }
    },
    mounted() {
        var hideModalList = TokenService.getHideModalList();

        api.post('/admin/notice/list', {
            noticeWho: 'TMS',
            today: this.$store.getters.getToday,
        })
            .then((response) => {
                var data = response.data.noticeList;
                this.noticeList = data.filter((v) => hideModalList.includes(v.noticeNo) == false);
                if (this.noticeList.length > 0) this.isOnBackground = true;
            })
            .catch((error) => {
                this.isOnBackground = false;
                alert(error.returnMessage);
            });
    },
    methods: {
        closeModal() {
            this.cntCloseModal += 1;
            if (this.noticeList.length == this.cntCloseModal) {
                this.isOnBackground = false;
            }
        },
    },
};
</script>

<style>
.modal-background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background-color: rgba(17, 17, 17, 0.4);
}
</style>
