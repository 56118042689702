import Vue from 'vue';
import App from './App.vue';
import router from './router/index';
import store from '@/store/store';
import VueNaverMaps from 'vue-naver-maps';

Vue.config.productionTip = false;

// 네이버지도 API
Vue.use(VueNaverMaps, {
  clientID: 'u10to01zzr',
  useGlobalAPI: true
});

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');
