<template>
    <router-view :user="user" />
</template>

<script>
import TokenService from './api/tokenService';

export default {
    name: 'App',
    data() {
        return {
            user: null,
        };
    },
    created() {
        this.user = TokenService.getUser();
    },
};
</script>
