import Vue from 'vue';
import VueRouter from 'vue-router';

import Dashboard from '@/views/common/Dashboard';
import SignIn from '@/views/authentication/SignIn.vue';

import DefaultLayout from '@/layouts/default/Index.vue';
import SinglePageLayout from '@/layouts/singlepage/Index.vue';
import TokenService from '@/api/tokenService';

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => {
        if (err.name !== 'NavigationDuplicated') throw err;
    });
};

const routes = [
    /* Top / Left / Content LayOut */
    {
        path: '/login',
        name: 'login',
        component: SignIn,
    },
    {
        path: '/dispatch/DailyOrder',
        name: 'DailyOrder',
        component: () => import('../views/dispatch/DailyOrder.vue'),
    },
    {
        path: '*',
        component: () => import('../views/common/NotFound.vue'),
    },
    {
        path: '/',
        component: DefaultLayout,
        children: [
            {
                path: '/',
                name: 'Dashboard',
                component: Dashboard,
                meta: { requiresAuth: true },
            },
            /* 기준정보 route정보 START */
            {
                path: '/basic/DeliveryCar',
                name: 'DeliveryCar',
                component: () => import('../views/basic/DeliveryCar.vue'),
                meta: { requiresAuth: true, authorization: ['admin'] },
            },
            {
                path: '/basic/ItemList',
                name: 'ItemList',
                component: () => import('../views/basic/ItemList.vue'),
                meta: { requiresAuth: true },
            },
            {
                path: '/basic/BizAreaList',
                name: 'BizAreaList',
                component: () => import('../views/basic/BizAreaList.vue'),
                meta: { requiresAuth: true },
            },
            {
                path: '/basic/PartnerList',
                name: 'PartnerList',
                component: () => import('../views/basic/PartnerList.vue'),
                meta: { requiresAuth: true },
            },
            {
                path: '/basic/OutPartnerList',
                name: 'OutPartnerList',
                component: () => import('../views/basic/OutPartnerList.vue'),
                meta: { requiresAuth: true },
            },
            {
                path: '/basic/DeliveryPrice',
                name: 'DeliveryPrice',
                component: () => import('../views/basic/DeliveryPrice.vue'),
                meta: { requiresAuth: true },
            },
            {
                path: '/basic/RegionList',
                name: 'RegionList',
                component: () => import('../views/basic/RegionList.vue'),
                meta: { requiresAuth: true },
            },
            /* 기준정보 route정보 END */
            /* 배차 route정보 START */
            {
                path: '/dispatch/DispatchMgmt',
                name: 'DispatchMgmt',
                component: () => import('../views/dispatch/DispatchMgmt.vue'),
                meta: { requiresAuth: true },
            },
            {
                path: '/dispatch/WareHouseMgmt',
                name: 'WareHouseMgmt',
                component: () => import('../views/dispatch/WareHouseMgmt.vue'),
                meta: { requiresAuth: true },
            },
            {
                path: '/dispatch/MoveWarehouseMgmt',
                name: 'MoveWarehouseMgmt',
                //component: () => import('../views/dispatch/MoveWarehouseMgmt.vue'),
                component: () => import('../views/common/CommingSoon.vue'),
                meta: { requiresAuth: true },
            },
            {
                path: '/dispatch/BackhaulMgmt',
                name: 'BackhaulMgmt',
                component: () => import('../views/dispatch/BackhaulMgmt.vue'),
                meta: { requiresAuth: true },
            },
            /* 배차 route정보 END */
            /* 실적 route정보 START */

            /* 실적 route정보 END */
            /* 정산 route정보 START */
            {
                path: '/performance/DeliveryMgmt',
                name: 'DeliveryMgmt',
                component: () => import('../views/performance/DeliveryMgmt.vue'),
                meta: { requiresAuth: true },
            },
            {
                path: '/performance/DeliveryHistory',
                name: 'DeliveryHistory',
                component: () => import('../views/performance/DeliveryHistory.vue'),
                meta: { requiresAuth: true },
            },
            {
                path: '/performance/TransitMgmt',
                name: 'TransitMgmt',
                component: () => import('../views/performance/TransitMgmt.vue'),
                meta: { requiresAuth: true },
            },
            /* 정산 route정보 END */

            /* 통계 메뉴 route정보 START */
            {
                path: '/statistics/StatisticsByCar',
                name: 'StatisticsByCar',
                component: () => import('../views/statistics/StatisticsByCar.vue'),
                meta: { requiresAuth: true },
            },
            {
                path: '/statistics/StatisticsByPartner',
                name: 'StatisticsByPartner',
                component: () => import('../views/statistics/StatisticsByPartner.vue'),
                meta: { requiresAuth: true },
            },
            /* 통계 메뉴 route정보 END */

            /* 공통 메뉴 route정보 START */
            {
                path: '/admin/UserMgmt',
                name: 'UserMgmt',
                component: () => import('../views/admin/UserMgmtView.vue'),
                meta: { requiresAuth: true },
            },
            {
                path: '/admin/AuthMgmt',
                name: 'AuthMgmt',
                component: () => import('../views/admin/AuthMgmtView.vue'),
                meta: { requiresAuth: true },
            },
            {
                path: '/admin/MenuMgmt',
                name: 'MenuMgmt',
                component: () => import('../views/admin/MenuMgmtView.vue'),
                meta: { requiresAuth: true },
            },
            {
                path: '/admin/CodeMgmt',
                name: 'CodeMgmt',
                component: () => import('../views/admin/CodeMgmtView.vue'),
                meta: { requiresAuth: true },
            },
            {
                path: '/admin/NoticeMgmt',
                name: 'NoticeMgmt',
                component: () => import('../views/admin/NoticeMgmtView.vue'),
                meta: { requiresAuth: true },
            },
            {
                path: '/sample/ComponentGuide',
                name: 'ComponentGuide',
                component: () => import('../views/sample/ComponentGuide.vue'),
                meta: { requiresAuth: true },
            },

            /* 공통 메뉴 route정보 END */
        ],
    },

    /* Only Content LayOut */
    {
        path: '/singlepage',
        component: SinglePageLayout,
        children: [
            {
                path: 'sign-in',
                name: 'SignIn',
                component: SignIn,
            },
        ],
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    const access_token = TokenService.getAccessToken();
    //const { authorization } = to.meta;

    if (!access_token) {
        if (to.meta.requiresAuth) {
            //    alert('로그인 후 이용해주세요.');
            next('/login');
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router;
