const user = {
    namespaced: true,
    state: {
        userInfo: {},
        userId: '',
        userNm: '',
        role: '',
        deptCd: '',
    },
    mutations: {
        setUser: function (state, payload) {
            state.userInfo = payload;
            state.userId = payload.userId;
            state.userNm = payload.userNm;
            state.role = payload.role;
            state.deptCd = payload.deptCd;
        },
    },
};

export default user;
