import axiosInstance from './api';
import TokenService from './tokenService';
import router from '@/router/index';

axiosInstance.interceptors.request.use(
    (config) => {
        if (config.url !== '/login' && config.url !== '/admin/user/reissue') {
            const token = TokenService.getAccessToken();

            if (token) {
                config.headers['Authorization'] = token;
            }
        }
        return config;
    },
    (error) => {
        console.log('req : ', error);
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    (res) => {
        return res;
    },
    async (error) => {
        // access token 없을 시 재발급 진행
        const originalConfig = error.config;

        if (originalConfig.url !== '/login' && error.response) {
            if (error.response.status === 401 && !originalConfig._retry) {
                if (
                    error.response.data.returnCode == '409' &&
                    error.response.data.returnMessage == 'Access token is expired.'
                ) {
                    originalConfig._retry = true;

                    try {
                        const rs = await axiosInstance.post('/admin/user/reissue', {});

                        if (rs.data.returnCode == '200' && rs.data.returnMessage == 'Success') {
                            TokenService.updateAccessToken(rs.headers.authorization);

                            return axiosInstance(originalConfig);
                        } else {
                            // 로그아웃
                            console.log('interceptor Error : ', rs);
                            logout();
                            return null;
                        }
                    } catch (_error) {
                        // 로그아웃

                        if (_error.response && _error.response.data) {
                            console.log('_error : ', _error.response);
                        }

                        logout();
                        return new Promise(() => {});
                        //return Promise.reject(_error);
                    }
                }
            }
        }

        if (error.response.status === 404) {
            // 404 Not Found 에러 처리
            console.log('404 ERROR ', error.response);
            return Promise.reject(error.response.data);
        }

        if (error.response.status === 500) {
            // Refresh Token Expired. returnCode : 405
            // reissue 다시 시도
            console.log('500 ERROR ', error.response);

            // Refresh token expired
            // 405
            if (error.response.data.returnCode === '405') {
                logout();
                return new Promise(() => {}); // 호출 api의 catch로 넘기지 않음.
            } else {
                return Promise.reject(error.response.data);
            }
        }

        return Promise.reject(error);
    }
);

function logout() {
    console.log('interceptor logout');
    axiosInstance
        .post('/logout')
        .then((res) => {
            alert('인증이 만료되었습니다.\n다시 로그인하세요.');
            router.push('/login');
        })
        .catch((err) => {
            console.log(err.toString());
        });
}

export default axiosInstance;
