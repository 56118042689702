<template>
    <div>
        <div class="chart-align">
            <div class="wrap-aboutGrid">
                <button class="btn sm" @click="toggleFullScreen()">풀스크린</button>
            </div>
            <div class="chart-area-grid">
                <div class="jqx-charts-container">
                    <div class="jqx-chart-row">
                        <!-- 좌상단 바 그래프 -->
                        <JqxChart class="jqx-chart" ref="monthlyOrderChart" style="width: 40%; height: 250px" :title="'월간 주문건수'" :description="''" :enableAnimations="false" :showLegend="false" :padding="padding"
                                  :titlePadding="titlePadding" :source="monthlyCountData" :xAxis="xAxis" :valueAxis="valueAxis" :seriesGroups="seriesGroups2" :colorScheme="'scheme20'"/>
                        <!-- 우상단 파이차트 -->
                        <JqxChart class="jqx-chart" ref="dailyOrderChart" style="width: 15%; height: 250px;" :title="'금일 주문건수'" :description="''" :showLegend="false" :enableAnimations="false" :padding="padding" @click="chartEvent([10,20,30,40,50,60,70])"
                                  :titlePadding="titlePadding" :source="dailyCountData" :showToolTips="false" :seriesGroups="seriesGroups" :colorScheme="'scheme05'" :backgroundColor="'#FAFAFA'" :drawBefore="drawBefore"/>
                        <JqxChart class="jqx-chart" ref="waitChart" style="width: 15%; height: 250px;" :title="'출고대기'" :description="''" :showLegend="false" :enableAnimations="false" :padding="padding" @click="chartEvent([10])"
                                  :titlePadding="titlePadding" :source="waitCountData" :showToolTips="false" :seriesGroups="seriesGroups" :colorScheme="'scheme09'" :backgroundColor="'#FAFAFA'" :drawBefore="drawBefore"/>
                        <JqxChart class="jqx-chart" ref="onGoingChart" style="width: 15%; height: 250px;" :title="'출고중'" :description="''" :showLegend="false" :enableAnimations="false" :padding="padding" @click="chartEvent([20,30])"
                                  :titlePadding="titlePadding" :source="onGoingCountData" :showToolTips="false" :seriesGroups="seriesGroups" :colorScheme="'scheme07'" :backgroundColor="'#FAFAFA'" :drawBefore="drawBefore"/>
                        <JqxChart class="jqx-chart" ref="completeChart" style="width: 15%; height: 250px;" :title="'출고완료'" :description="''" :showLegend="false" :enableAnimations="false" :padding="padding" @click="chartEvent([40,50,60,70])"
                                  :titlePadding="titlePadding" :source="completeCountData" :showToolTips="false" :seriesGroups="seriesGroups" :colorScheme="'scheme08'" :backgroundColor="'#FAFAFA'" :drawBefore="drawBefore"/>
                    </div>
                </div>
            </div>
        </div>
        <div class="align">
            <div class="area-grid" style="flex: 10; width:100%; height:100%">
                <JqxGrid
                    ref="dataGrid"
                    :width="'calc(100% - 3px)'"
                    :height="480"
                    :source="dataAdapter"
                    :columns="dataColumns"
                    :columnsresize="true"
                    :altrows="true"
                    :theme="'eyh'"
                    :filterable="false"
                    :showfilterrow="false"
                />
            </div>
        </div>
    </div>
</template>
<script>
    import api from '@/api/api-interceptors';
    import JqxChart from "jqwidgets-scripts/jqwidgets-vue/vue_jqxchart.vue";
    import JqxGrid from 'jqwidgets-scripts/jqwidgets-vue/vue_jqxgrid.vue';
    export default {
        components: {
            JqxChart,
            JqxGrid
        },
        watch: {
            filterArr(){
                this.filterEvent();
            }
        },
        data: function () {
            return {
                // eslint-disable-next-line
                dataAdapter: new jqx.dataAdapter(this.source),
                dataColumns: [
                    { text: '주문채번번호', datafield: 'noJumunOrderSerial', align: 'center', cellsalign: 'center', width: '10%' ,editable: false},
                    { text: '출고계획일자', datafield: 'dtDelivery', align: 'center', cellsalign: 'center', cellsformat: 'yyyy-MM-dd', width: '10%' ,editable: false},
                    { text: '납기요청일시', datafield: 'eta', align: 'center', cellsalign: 'center', width: '10%' ,editable: false},
                    { text: '차량번호', datafield: 'noCar', align: 'center', cellsalign: 'center', width: '10%', editable: false},
                    { text: '거래처', datafield: 'nmPartner', align: 'center', cellsalign: 'left', width: '20%' ,editable: false},
                    { text: '납품처', datafield: 'nmOutPartner', align: 'center', cellsalign: 'left', width: '20%' ,editable: false},
                    { text: '배차상태', datafield: 'stsDispatch', displayfield: 'nmStsDispatch', align: 'center', cellsalign: 'center', width: '10%', cellsrenderer: this.cellsrendererStsDispatch, editable: false },
                    { text: '운행상태', datafield: 'stsDelivery', displayfield: 'nmStsDelivery', align: 'center', cellsalign: 'center', width: '10%', cellsrenderer: this.cellsrendererStsDelivery, editable: false },
                ],
                dailyCountData: [{ text: '전체 주문건수', value: 0 }],
                waitCountData: [{ text: '출고대기', value: 0 },{ text: '', value: 0 }],
                onGoingCountData: [{ text: '출고중', value: 0 },{ text: '', value: 0 }],
                completeCountData: [{ text: '출고완료', value: 0 },{ text: '', value: 0 }],
                monthlyCountData: [{ 기준일자: '전월', value: 0},{ 기준일자: '당월', value: 0}],
                padding: { left: 5, top: 5, right: 5, bottom: 5 },
                titlePadding: { left: 5, top: 5, right: 5, bottom: 5 },
                seriesGroups: [
                    {
                        type: 'donut',
                        useGradientColors: false,
                        series:
                            [
                                {
                                    showLabels: false,
                                    enableSelection: true,
                                    displayText: 'text',
                                    dataField: 'value',
                                    labelRadius: 120,
                                    initialAngle: 90,
                                    radius: 60,
                                    innerRadius: 40,
                                    centerOffset: 0,
                                    rotation: 'counterclockwise',
                                }
                            ]
                    }
                ],
                xAxis: {
                    dataField: '기준일자',
                    gridLines: { visible: true },
                    flip: false,
                    labels:
                        {
                            angle: 90,
                            horizontalAlignment: 'right',
                            verticalAlignment: 'center',
                            rotationPoint: 'right',
                            offset: { x: 0, y: -5 }
                        }
                },
                valueAxis: {
                    flip: true,
                    labels: {
                        visible: true,
                        formatFunction: (value) => {
                            return parseInt(value);
                        }
                    },
                    minValue: 0
                },
                seriesGroups2: [
                {
                        type: 'column',
                        orientation: 'horizontal',
                        columnsGapPercent: 70,
                        toolTipFormatSettings: { thousandsSeparator: ',' },
                        series: [
                            { dataField: 'value', displayText: '주문건수' }
                        ]
                    }
                ],
                fullScreen: false,
                //실시간 갱신여부 => true
                isContinue: true,
                filterArr: []
            }
        },
        beforeCreate: function () {
            const deliveryPlaceSource = {
                datatype: 'json',
                datafields: [
                    { name: 'value', type: 'string' },
                    { name: 'label', type: 'string' },
                ],
                localdata: this.$store.getters.getCodeDtlByGrpCd('DELIVERY_PLACE', '전체', ''),
            };
            // eslint-disable-next-line
            this.deliveryPlaceAdapter = new jqx.dataAdapter(deliveryPlaceSource, { autoBind: true });

            const stsDispatchSource = {
                datatype: 'json',
                datafields: [
                    { name: 'value', type: 'string' },
                    { name: 'label', type: 'string' },
                ],
                localdata: this.$store.getters.getCodeDtlByGrpCd('STS_DISPATCH', '전체', ''),
            };
            // eslint-disable-next-line
            this.stsDispatchAdapter = new jqx.dataAdapter(stsDispatchSource, { autoBind: true });

            const stsDeliverySource = {
                datatype: 'json',
                datafields: [
                    { name: 'value', type: 'string' },
                    { name: 'label', type: 'string' },
                ],
                localdata: this.$store.getters.getCodeDtlByGrpCd('STS_DELIVERY', '전체', ''),
            };
            // eslint-disable-next-line
            this.stsDeliveryAdapter = new jqx.dataAdapter(stsDeliverySource, { autoBind: true });

            this.counter = 0;
            this.latencyThreshold = 260;
            this.source = {
                localdata: [],
                datafields: [
                    { name: 'cdDeliveryPlace', type: 'string' },
                    {
                        name: 'nmDeliveryPlace',
                        value: 'cdDeliveryPlace',
                        values: { source: this.deliveryPlaceAdapter.records, value: 'value', name: 'label' },
                    },
                    { name: 'noJumunOrderSerial', type: 'string' },
                    { name: 'noCar', type: 'string' },
                    { name: 'dtCarRegist', type: 'string' },
                    { name: 'tonCarWeight', type: 'string' },
                    { name: 'carType', type: 'string' },
                    { name: 'nmCarOwner', type: 'string' },
                    { name: 'noTelCarOwner', type: 'string' },
                    { name: 'dtDelivery', type: 'date' },
                    { name: 'transRegionCd', type: 'string' },
                    { name: 'nmRegion', type: 'string' },
                    { name: 'nmPartner', type: 'string' },
                    { name: 'nmOutPartner', type: 'string' },
                    { name: 'carStatus', type: 'string' },
                    { name: 'insertDate', type: 'string' },
                    { name: 'txtAddr', type: 'string' },
                    { name: 'eta', type: 'string' },
                    { name: 'stsDispatch', type: 'string' },
                    {
                        name: 'nmStsDispatch',
                        value: 'stsDispatch',
                        values: { source: this.stsDispatchAdapter.records, value: 'value', name: 'label' },
                    },
                    { name: 'stsDelivery', type: 'string' },
                    {
                        name: 'nmStsDelivery',
                        value: 'stsDelivery',
                        values: { source: this.stsDeliveryAdapter.records, value: 'value', name: 'label' },
                    },
                ],
                datatype: 'json',
            };
        },
        methods: {
            drawBefore: function (renderer, rect) {
                let value;
                if (this.counter === 0) {
                    value = this.dailyCountData[0].value;
                } else if (this.counter === 1) {
                    value = this.waitCountData[0].value;
                } else if (this.counter === 2) {
                    value = this.onGoingCountData[0].value;
                } else if (this.counter === 3) {
                    value = this.completeCountData[0].value;
                }
                let sz = renderer.measureText(value, 0, { 'class': 'jqx-chart-inner-text' });
                renderer.text(
                    value,
                    rect.x + (rect.width - sz.width) / 2,
                    rect.y + rect.height / 2,
                    0,
                    0,
                    0,
                    { 'class': 'jqx-chart-inner-text' }
                );
                this.counter ++;
            },
            retrivePerformance: function() {
                if(!this.isContinue) return false;
                api.post('/perfomance/deliveryStatus/retrivePerformance', {})
                .then((response) => {
                    let waitCount = response.data.deliveryStatusOfJumunOrder.WAITCOUNT;
                    let onGoningCount = response.data.deliveryStatusOfJumunOrder.ONGOINGCOUNT;
                    let completeCount = response.data.deliveryStatusOfJumunOrder.COMPLETECOUNT;
                    let totalCount = waitCount + onGoningCount + completeCount;

                    let lastMonthCnt = response.data.jumunOrderMonthlyCount.LAST_MONTH;
                    let thisMonthCnt = response.data.jumunOrderMonthlyCount.THIS_MONTH;

                    this.dailyCountData[0].value = totalCount;

                    this.waitCountData[0].value = waitCount;
                    this.waitCountData[1].value = onGoningCount + completeCount;

                    this.onGoingCountData[0].value = onGoningCount;
                    this.onGoingCountData[1].value = waitCount + completeCount;

                    this.completeCountData[0].value = completeCount;
                    this.completeCountData[1].value = waitCount + onGoningCount;

                    this.dailyCountData = [...this.dailyCountData];
                    this.waitCountData = [...this.waitCountData];
                    this.onGoingCountData = [...this.onGoingCountData];
                    this.completeCountData = [...this.completeCountData];
                    this.monthlyCountData = [...this.monthlyCountData];
                    this.counter = 0;

                    this.monthlyCountData[0].value = lastMonthCnt;
                    this.monthlyCountData[1].value = thisMonthCnt;

                    if(this.$refs.monthlyOrderChart) this.$refs.monthlyOrderChart.update();
                    if(this.$refs.dailyOrderChart) this.$refs.dailyOrderChart.update();
                    if(this.$refs.waitChart) this.$refs.waitChart.update();
                    if(this.$refs.onGoingChart) this.$refs.onGoingChart.update();
                    if(this.$refs.completeChart) this.$refs.completeChart.update();

                    this.source.localdata = response.data.performanceHistoryList;
                    this.$refs.dataGrid.updatebounddata('cells');
                })
                .catch((error) => {
                    // alert(error.returnMessage);
                })
                //대시보드 갱신주기
                setTimeout(() => {
                    this.retrivePerformance();
                }, 1000*10);
            },
            toggleFullScreen: function() {
                this.fullScreen = !this.fullScreen;

                let nav = document.querySelector('nav');
                let section = document.querySelector('section');
                let header = document.querySelector('.page-information');

                if(this.fullScreen){
                    header.style.display = 'none';
                    nav.style.display = 'none';
                    section.style.position = 'static';
                }else{
                    header.style.display = 'flex';
                    nav.style.display = 'flex';
                    section.style.position = 'absolute';
                }
            },
            chartEvent: function(stsArr) {
                this.filterArr = stsArr;
            },
            filterEvent: function() {
                // eslint-disable-next-line
                let filtergroup = new jqx.filter();
                let filtercondition = 'contains';
                let filter_or_operator = 1;
                this.filterArr.forEach(filterValue => {
                    let filter = filtergroup.createfilter('stringfilter', filterValue, filtercondition);
                    filtergroup.addfilter(filter_or_operator, filter);
                })

                this.$refs.dataGrid.addfilter('stsDelivery', filtergroup);
                this.$refs.dataGrid.applyfilters();
            },
            cellsrendererStsDispatch: function(row, columnfield, value, defaulthtml, columnproperties, rowdata) {
                let stsDispatch = Number(rowdata.stsDispatch);
                if(stsDispatch == 10 || stsDispatch == 40 || stsDispatch == 50) return ( '<div class="jqx-grid-cell-middle-align grid-status-red">' + (value) + '</div>' );
                if(stsDispatch == 20 || stsDispatch == 21 || stsDispatch == 30) return ( '<div class="jqx-grid-cell-middle-align grid-status-yellow">' + (value) + '</div>' );
                if(stsDispatch == 60) return ( '<div class="jqx-grid-cell-middle-align grid-status-green">' + (value) + '</div>' );
            },
            cellsrendererStsDelivery: function(row, columnfield, value, defaulthtml, columnproperties, rowdata) {
                let stsDelivery = Number(rowdata.stsDelivery);
                if(stsDelivery == 10 || stsDelivery == 20 || stsDelivery == 30) return ( '<div class="jqx-grid-cell-middle-align grid-status-red">' + (value) + '</div>' );
                if(stsDelivery == 40 || stsDelivery == 50 || stsDelivery == 60) return ( '<div class="jqx-grid-cell-middle-align grid-status-yellow">' + (value) + '</div>' );
                if(stsDelivery == 70) return ( '<div class="jqx-grid-cell-middle-align grid-status-green">' + (value) + '</div>' );
            },
        },
        mounted() {
            this.retrivePerformance();
        },
        beforeDestroy() {
            //실시간 갱신여부 => false
            this.isContinue = false;
        },
    }
</script>