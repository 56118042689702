<template>
    <div id="app">
        <div class="login">
            <h1>Robo TMS</h1>
            <h2>신대양제지 운송관리시스템</h2>
            <form v-on:submit.prevent="login">
                <div class="ipt-wrap">
                    <div class="ipt lg">
                        <div>
                            <input
                                type="text"
                                v-model="username"
                                @blur="validateId"
                                placeholder="아이디를 입력해주세요"
                            />
                        </div>
                        <span v-if="errorMessageId" class="error">{{ errorMessageId }}</span>
                    </div>
                    <div class="ipt lg">
                        <div>
                            <input
                                type="password"
                                v-model="password"
                                @blur="validatePw"
                                placeholder="비밀번호를 입력해주세요"
                            />
                        </div>
                        <span v-if="errorMessagePw" class="error">{{ errorMessagePw }}</span>
                    </div>
                </div>
                <div class="btn-wrap">
                    <button class="btn primary">로그인</button>
                </div>
            </form>
            <div class="ipt-wrap">
                <div class="ipt">
                    <div>
                        <label>
                            <input type="checkbox" v-model="saveId" @change="handleCheckbox" />
                            <span>아이디 저장하기</span>
                        </label>
                    </div>
                </div>
            </div>
            <!-- <div class="btn-wrap">
                <button class="btn" v-on:click="resetPassword()">비밀번호 변경</button>
            </div> -->
        </div>
    </div>
</template>

<script>
import api from '@/api/api-interceptors';
import TokenService from '@/api/tokenService';

export default {
    name: 'login',
    data: function () {
        return {
            saveId: TokenService.getIsSaveId() === 'true',
            username: '',
            password: '',
            errorMessageId: '',
            errorMessagePw: '',
            showComponent: true,
        };
    },
    mounted: function () {
        if (this.saveId) {
            let id = TokenService.getLoginId();
            if (id != null) this.username = id;
        }
    },
    methods: {
        validateId() {
            if (!this.username) {
                this.errorMessageId = '필수 입력 항목입니다.';
                return false;
            } else {
                this.errorMessageId = '';
            }
            return true;
        },
        validatePw() {
            if (!this.password) {
                this.errorMessagePw = '필수 입력 항목입니다.';
                return false;
            } else {
                this.errorMessagePw = '';
            }
            return true;
        },
        resetPassword: function (aa) {
            //alert('비밀번호 변경 화면으로 이동');
        },
        login() {
            if (!this.validateId() || !this.validatePw()) return;

            api.post('/login', {
                userId: this.username,
                userPw: this.password,
            }).then(
                (response) => {
                    let res = response.data;

                    if (res.returnCode == 200 && res.returnMessage == 'Success') {
                        var role = res.userEntity.role;
                        if (role === 'ROLE_KIOSK' || role === 'ROLE_MOBILE') {
                            alert('로그인 권한이 없습니다.');
                            return;
                        }

                        TokenService.setUser(res.userEntity, response.headers.authorization);
                        if (this.saveId) TokenService.setLoginId(this.username);
                        this.$store.commit('user/setUser', res.userEntity);
                        this.$router.push({ path: '/' });
                    }
                },
                (error) => {
                    let res = error.response.data;
                    if (res) {
                        alert(res.errorDetail);
                    }

                    if (error.response && error.response.status === 403) {
                        console.log('login error');
                    }
                }
            );
        },
        handleCheckbox(event) {
            let value = event.target.checked;
            if (!value) TokenService.deleteLoginId();
            TokenService.setIsSaveId(value);
        },
    },
};
</script>
