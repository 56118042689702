class TokenService {
    getAccessToken() {
        const user = JSON.parse(sessionStorage.getItem('user'));
        return user?.accessToken;
    }

    updateAccessToken(token) {
        console.log('updateLocalAccessToken');
        let user = JSON.parse(sessionStorage.getItem('user'));
        user.accessToken = token;
        sessionStorage.setItem('user', JSON.stringify(user));
    }

    getUser() {
        return JSON.parse(sessionStorage.getItem('user'));
    }

    getUserRole() {
        const user = JSON.parse(sessionStorage.getItem('user'));
        return user?.role;
    }

    setUser(user, token) {
        user.accessToken = token;
        sessionStorage.setItem('user', JSON.stringify(user));
    }

    removeUser() {
        sessionStorage.removeItem('user');
    }

    setIsSaveId(check) {
        localStorage.setItem('isSaveId', check);
    }

    getIsSaveId() {
        return localStorage.getItem('isSaveId');
    }

    setLoginId(id) {
        let info = {
            id: id,
        };
        localStorage.setItem('loginId', JSON.stringify(info));
    }

    getLoginId() {
        const info = JSON.parse(localStorage.getItem('loginId'));
        return info?.id;
    }

    deleteLoginId() {
        localStorage.removeItem('loginId');
    }

    getPersistedState() {
        return localStorage.getItem('myData');
    }

    setHideModalList(noticeNo) {
        let hide = JSON.parse(localStorage.getItem('hideModalList') || '[]');
        if (!hide.includes(noticeNo)) hide.push(noticeNo);
        localStorage.setItem('hideModalList', JSON.stringify(hide));
    }

    getHideModalList() {
        return JSON.parse(localStorage.getItem('hideModalList') || '[]');
    }
}

export default new TokenService();
