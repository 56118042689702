import Vue from 'vue';
import Vuex from 'vuex';
import user from './modules/user';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

const todayDate = new Date(Date.now());
const thisYear = todayDate.getFullYear();
const thisMonth = String(todayDate.getMonth() + 1).padStart(2, '0');

const persistedState = createPersistedState({
    key: 'myData',
    paths: ['BIZ_AREA', 'CODE_ITEMS', 'ERP_CODE_ITEMS', 'TREE_MENU_STATE', 'AUTH_ITEMS'],
});

const store = new Vuex.Store({
    modules: {
        user,
    },
    state: {
        AUTH_ITEMS: [], // 권한
        BIZ_AREA: [], // 부서
        CODE_ITEMS: [], // 공통코드
        ERP_CODE_ITEMS: [], // ERP 공통코드
        TREE_MENU_STATE: {},
        MENU_ITEMS: [],
    },
    mutations: {
        initCodes: function (state, { bizAreaList, codeItems, erpCodeItems, menuItems }) {
            state.BIZ_AREA = bizAreaList;
            state.CODE_ITEMS = codeItems;
            state.ERP_CODE_ITEMS = erpCodeItems;
            //state.MENU_ITEMS = menuItems;
        },
        setAuthList(state, payload) {
            state.AUTH_ITEMS = payload;
        },
        setTreeState: function (state, { menu, status }) {
            state.TREE_MENU_STATE[menu] = status;
        },
    },
    getters: {
        getToday() {
            return thisYear + '-' + thisMonth + '-' + todayDate.getDate().toString().padStart(2, '0');
        },
        getYearStart() {
            return thisYear + '-01-01';
        },
        getMonStart() {
            return thisYear + '-' + thisMonth + '-01';
        },
        getMonEnd() {
            let day = new Date(thisYear, thisMonth, 0).getDate();
            return thisYear + '-' + thisMonth + '-' + day;
        },
        getCodeDtlByGrpCd: (state) => (grpCd, addTxt, addVal) => {
            let rtnCode = [];
            if (addTxt !== null && addTxt !== undefined) {
                var addElement = { cdNo: addVal, cdNm: addTxt, value: addVal, label: addTxt };
                rtnCode.push(addElement);
            }
            state.CODE_ITEMS.forEach((element) => {
                if (element.grpCd === grpCd) {
                    rtnCode.push(element);
                }
            });
            return rtnCode;
        },
        getErpCodeDtlByGrpCd: (state) => (grpCd, addTxt, addVal) => {
            let rtnCode = [];
            if (addTxt !== null && addTxt !== undefined) {
                var addElement = { cdNo: addVal, cdNm: addTxt, value: addVal, label: addTxt };
                rtnCode.push(addElement);
            }
            state.ERP_CODE_ITEMS.forEach((element) => {
                if (element.grpCd === grpCd) {
                    rtnCode.push(element);
                }
            });
            return rtnCode;
        },
    },
    plugins: [persistedState],
});

export default store;
